import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

// custom typefaces
import "swiper/css"
import "bootstrap/scss/bootstrap.scss"
import "./src/main.sass"


export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcMPz4aAAAAAGGAslt9ZIFCO3u2i5hjDRrUswTC"
      scriptProps={{
        defer: true,
      }}
    >
      {element}
    </GoogleReCaptchaProvider>
  )
}